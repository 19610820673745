import React from 'react'

export const SidebarData = [
  // {
  //   title: 'Dashboard',
  //   path: '/dashboard',
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="22"
  //       height="18"
  //       viewBox="0 0 18 18"
  //     >
  //       <g fill="#B7A1A1" transform="translate(-38 -145)">
  //         <rect
  //           width="8.714"
  //           height="7.714"
  //           data-name="Rectangle 10"
  //           rx="1"
  //           transform="translate(48.286 145)"
  //         ></rect>
  //         <rect
  //           width="8.714"
  //           height="7.714"
  //           data-name="Rectangle 13"
  //           rx="1"
  //           transform="translate(48.286 155.286)"
  //         ></rect>
  //         <rect
  //           width="8.714"
  //           height="7.714"
  //           data-name="Rectangle 11"
  //           rx="1"
  //           transform="translate(38 145)"
  //         ></rect>
  //         <rect
  //           width="8.714"
  //           height="7.714"
  //           data-name="Rectangle 12"
  //           rx="1"
  //           transform="translate(38 155.286)"
  //         ></rect>
  //       </g>
  //     </svg>
  //   ),
  //   cName: 'nav-text',
  //   isActive: true,
  // },
  {
    title: 'Customers',
    path: '/customers',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24.714"
        height="18"
        viewBox="0 0 25.714 18"
      >
        <path
          fill="#b7a1a1"
          d="M3.857 9.964a2.571 2.571 0 10-2.571-2.571 2.574 2.574 0 002.571 2.571zm18 0a2.571 2.571 0 10-2.571-2.571 2.574 2.574 0 002.571 2.571zm1.286 1.286h-2.572a2.564 2.564 0 00-1.812.75 5.877 5.877 0 013.017 4.4h2.652a1.284 1.284 0 001.286-1.286v-1.293a2.574 2.574 0 00-2.571-2.571zm-10.286 0a4.5 4.5 0 10-4.5-4.5 4.5 4.5 0 004.5 4.5zm3.086 1.286h-.333a6.213 6.213 0 01-5.5 0h-.339a4.63 4.63 0 00-4.629 4.629v1.157a1.929 1.929 0 001.929 1.928h11.572a1.929 1.929 0 001.929-1.929v-1.157a4.63 4.63 0 00-4.629-4.628zM6.955 12a2.564 2.564 0 00-1.812-.747H2.571A2.574 2.574 0 000 13.821v1.286a1.284 1.284 0 001.286 1.286h2.647A5.892 5.892 0 016.955 12z"
          transform="translate(0 -2.25)"
        ></path>
      </svg>
    ),
    cName: 'nav-text',
    isActive: true,
  },
  {
    title: 'API Management',
    path: '/apimanagement',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21.995"
        height="20"
        viewBox="0 0 19.995 20"
      >
        <path
          fill="#b7a1a1"
          d="M22.844 14.5a2.573 2.573 0 011.651-2.4 10.2 10.2 0 00-1.234-2.973 2.607 2.607 0 01-1.047.224 2.567 2.567 0 01-2.348-3.614A10.168 10.168 0 0016.9 4.5a2.571 2.571 0 01-4.8 0 10.2 10.2 0 00-2.976 1.234 2.567 2.567 0 01-2.349 3.614 2.523 2.523 0 01-1.047-.224A10.424 10.424 0 004.5 12.1a2.572 2.572 0 01.005 4.8 10.2 10.2 0 001.234 2.973 2.568 2.568 0 013.39 3.39A10.258 10.258 0 0012.1 24.5a2.566 2.566 0 014.79 0 10.2 10.2 0 002.973-1.234 2.571 2.571 0 013.39-3.39A10.258 10.258 0 0024.49 16.9a2.585 2.585 0 01-1.646-2.4zm-8.3 4.16a4.166 4.166 0 114.166-4.166 4.164 4.164 0 01-4.166 4.164z"
          data-name="API Management"
          transform="translate(-4.5 -4.5)"
        ></path>
      </svg>
    ),
    cName: 'nav-text',
    isActive: false,
  },
  {
    title: 'Webhook Subscriptions',
    path: '/webhook',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21.385"
        height="20"
        viewBox="0 0 15.385 20"
      >
        <path
          fill="#b7a1a1"
          d="M15.981 3.375H8.673A1.923 1.923 0 006.75 5.3v16.152a1.923 1.923 0 001.923 1.923h11.539a1.923 1.923 0 001.923-1.923V9.529zm-.769 6.925V4.913L20.6 10.3z"
          data-name="Webhook"
          transform="translate(-6.75 -3.375)"
        ></path>
      </svg>
    ),
    cName: 'nav-text ',
    isActive: false,
  },
  {
    title: 'User Activity',
    path: '/useractivity',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19.735"
        height="19.748"
        viewBox="0 0 19.735 19.748"
      >
        <path
          fill="#b7a1a1"
          fillRule="evenodd"
          d="M.265 10.141a9.868 9.868 0 1019.735 0 .9.9 0 10-1.794 0 8.093 8.093 0 11-1.616-4.832.9.9 0 001.615-.536V1.17a.9.9 0 10-1.794 0v1.374a9.854 9.854 0 00-16.147 7.6zm9.871-6.279a6.281 6.281 0 00-6.285 6.278 6.281 6.281 0 006.285 6.283A6.279 6.279 0 0014.572 5.7a6.353 6.353 0 00-4.436-1.838zm-.014 1.78a.9.9 0 01.908.915v3.211l2.43 2.43a.9.9 0 01-1.272 1.272L9.5 10.773a.9.9 0 01-.266-.634V6.556a.9.9 0 01.888-.915z"
          data-name="User Activity"
          transform="translate(-.265 -.261)"
        ></path>
      </svg>
    ),
    cName: 'nav-text ',
    isActive: false,
  },
]
