import React, { useState, useEffect } from 'react'
import './header.scss'
import userIconImage from '../../assets/icons/user.svg'
import down from '../../assets/icons/Icon ionic-ios-arrow-down.svg'
import logOut from '../../assets/icons/logout.svg'
import up from '../../assets/icons/up.svg'
import { Auth } from 'aws-amplify'
import { useHistory } from 'react-router'
import { useAppContext } from '../../library/Context'
import AppContext from '../../library/Context'

export const Header = () => {
  const { setUserHasAuthenticated } = useAppContext(AppContext)
  const [isDropdownShown, setIsDropdownShown] = useState(false)
  const menuRef = React.useRef()
  const dropdownHandler = (props) => {
    setIsDropdownShown(!isDropdownShown)
  }

  const history = useHistory()
  const account = Auth.Credentials.Auth

  const handleMenuClose = (e) => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      setIsDropdownShown(false)
      document.removeEventListener('click', handleMenuClose)
    } else {
      return null
    }
  }
  const handleLogout = async (e) => {
    e.preventDefault()
    await Auth.signOut()

    setUserHasAuthenticated(false)
    history.push('/login')
  }
  useEffect(() => {
    if (isDropdownShown) {
      document.addEventListener('click', handleMenuClose)
    } else {
      return null
    }
  }, [isDropdownShown])

  return (
    <>
      <header>
        <div className="userInfo">
          <div ref={menuRef} className="a" onClick={() => dropdownHandler()}>
            <span className="userIcon">
              <img src={userIconImage} alt="userIcon" />
            </span>

            <span className="userEmail">{account.user.attributes.email}</span>
            {!isDropdownShown ? (
              <img src={down} alt="downIcon" className="down" />
            ) : (
              <img src={up} alt="upIcon" className="up" />
            )}
          </div>
          {isDropdownShown ? (
            <div className="dropdownBox">
              {/* <div className="accountInfo">
                <span className="accountIcon">
                  <img src={userRed} alt="userIcon2" />
                </span>
                <span className="accountText">My Account</span>
                <span className="tooltipt">Coming Soon</span>
              </div> */}
              {/* {isAuthenticated && ( */}
              <div className="logOut" onClick={handleLogout}>
                <span className="logoutIcon">
                  <img src={logOut} alt="logOutIcon" />
                </span>
                <span className="logOutText">Log Out</span>
              </div>
              {/* <div className="vertical"></div> */}

              {/* )} */}
            </div>
          ) : null}
        </div>
      </header>

      <div className="red_bg"></div>
    </>
  )
}
