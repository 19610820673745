import React, { useState, useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Login from './pages/login/Login'
// import AboutUs from './pages/about-us/AboutUs'
// import Home from './pages/home/Home'
import { Auth } from 'aws-amplify'
import Dashboard from './pages/dashboard/Dashboard'
import Customers from './pages/customers/Customers'
import ApiManagement from './pages/apiManagement/ApiManagement'
import Webhook from './pages/webhook/Webhook'
import UserActivity from './pages/userActivity/UserActivity'
import { useAppContext } from './library/Context'

function Router() {
  const {isAuthenticated} = useAppContext();

  return (

    <Switch>
      <Route path="/login">
        {!isAuthenticated ? (
          <Login/>
        )
      : (
        <Redirect to = {{pathname:"/customers"}}/>
      )}
      </Route>

      <Route exact path ="/">
        <Redirect to ={{
          pathname : "/customers",
        }} />

        </Route>

      {/* <PrivateRoute  path="/dashboard" userAuthenticated={userAuthenticated}>
        <Dashboard />
      </PrivateRoute> */}

      <PrivateRoute path="/customers" isAuthenticated={isAuthenticated}>
        <Customers />
      </PrivateRoute>

      <PrivateRoute path="/apimanagement" isAuthenticated={isAuthenticated}>
        <ApiManagement />
      </PrivateRoute>

      <PrivateRoute path="/webhook" isAuthenticated={isAuthenticated} >
        <Webhook />
      </PrivateRoute>

      <PrivateRoute path="/useractivity" isAuthenticated={isAuthenticated}>
        <UserActivity />
      </PrivateRoute>
    </Switch>

  )
}
function PrivateRoute({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export default Router
