import React from 'react'
import './cards.scss'
import PropTypes from 'prop-types'

export const Cards = ({
  primary,
  backgroundColor,
  label,
  number,
  color,
  ...props
}) => {
  const mode = primary ? 'card--primary' : 'card--secondary'

  return (
    <div
      className={['dashboard_card']}
      style={backgroundColor && { backgroundColor }}
      {...props}
    >
      <div className={'dashboard_card_body'}>
        <div className={'dashboard_text'} style={color && { color }}>
          {label}
        </div>
        <div className={'dashboard_number'} style={color && { color }}>
          {number}
        </div>
      </div>
    </div>
  )
}

Cards.propTypes = {
  primary: PropTypes.bool,
  backgroundColor: PropTypes.string,
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
}

Cards.defaultProps = {
  backgroundColor: null,
  primary: false,
}
