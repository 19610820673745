import React, { useState, useEffect } from 'react'
import logoImg from '../../assets/images/omni.png'
import { Button } from '../../stories/Button/Button'
import './Login.scss'
import Amplify, { Auth } from 'aws-amplify'
import { useHistory } from 'react-router'
import { ChangePassword } from '../../components/changePassword/ChangePassword'
import AppContext, { useAppContext } from '../../library/Context'
import Loading from '../../components/loader/Loading'
import { Helmet } from 'react-helmet'

const initialValues = {
  Email: '',
  Password: '',
}

function Login() {
  const { setUserHasAuthenticated } = useAppContext(AppContext)
  const [login, setLogin] = useState(true)
  const [loginData, setLoginData] = useState(initialValues)
  const [loading, setLoading] = useState(false)
  const [changeModel, setChangeModel] = useState(false)
  const [currentUser, setCurrentUser] = useState(null)
  const [password, setPassword] = useState('')
  const [passwordVerify, setPasswordVerify] = useState('')
  const [emailErrors, setEmailErrors] = useState({})
  const [passwordErrors, setPasswordErrors] = useState({})
  const [newPasswordErrors, setNewPasswordErrors] = useState({})
  const [confirmPasswordErrors, setConfirmPasswordErrors] = useState({})
  const [forgotModal, setForgotModal] = useState(false)
  const [invalidEntry, setInvalidEntry] = useState(false)
  const [changeSuccess, setChangeSuccess] = useState(false)
  const [expiredPassword, setExpiredPassword] = useState(false)
  const history = useHistory()

  useEffect(() => {
    Amplify.configure({
      Auth: {
        mandatorySignIn: true,
        region: `${process.env.REACT_APP_REGION}`,
        userPoolId: `${process.env.REACT_APP_USER_POOL_ID}`,
        userPoolWebClientId: `${process.env.REACT_APP_CLIENT_ID}`,
      },
      API: {
        endpoints: [
          {
            name: 'dev',
            endpoint: `${process.env.REACT_APP_API_URL}`,
            region: `${process.env.REACT_APP_REGION}`,
          },
        ],
      },
    })
  }, [])

  const handleCloseChange = () => {
    setChangeModel(false)
    setPassword('')
    setPasswordVerify('')
  }
  const forgotPassword = () => {
    setForgotModal(true)
  }
  const handleSubmit = async (event) => {
    event.preventDefault()

    const isValid = formValidation()
    if (isValid) {
      setLoading(true)
      Auth.signIn(loginData.Email, loginData.Password)
        .then((results) => {
          if (results.challengeName === `NEW_PASSWORD_REQUIRED`) {
            setChangeModel(true)
          } else {
            setUserHasAuthenticated(true)
            history.push('/customers')
          }
          setCurrentUser(results)
          setLoading(false)
        })
        .catch((e) => {
          if (e.message === 'Incorrect username or password.') {
            setInvalidEntry(true)
          } else if (
            e.message ===
            'Temporary password has expired and must be reset by an administrator.'
          ) {
            setExpiredPassword(true)
          }
          setLoading(false)
        })
    }
  }

  const onExpiry = () => {
    setExpiredPassword(false)
    setLoginData(initialValues)
  }

  function formValidation() {
    const emailError = {}
    const passwordError = {}
    let isValid = true
    if (loginData.Email.trim().length < 1) {
      emailError.emailEmpty = 'Email is required!'
      isValid = false
    }

    if (loginData.Password.trim().length < 1) {
      passwordError.passEmpty = 'Password is required!'
      isValid = false
    }

    setEmailErrors(emailError)
    setPasswordErrors(passwordError)
    return isValid
  }
  function confirmValidations() {
    const newPasswordError = {}
    const confirmPasswordError = {}
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/

    let isValid = true

    if (password.trim().length < 1) {
      newPasswordError.passwordEmpty = 'Password is required!'
      isValid = false
    } else if (!regex.test(password)) {
      newPasswordError.passwordSpe =
        'Must contain 1 uppercase, 1 lowercase , 1 number and 1 special character'
      isValid = false
    }

    if (passwordVerify.trim().length < 1) {
      confirmPasswordError.passwordVEmpty = 'Password is required!'
      isValid = false
    } else if (!regex.test(passwordVerify)) {
      confirmPasswordError.passwordVSpe =
        'Must contain 1 uppercase, 1 lowercase , 1 number and 1 special character'
      isValid = false
    }
    setNewPasswordErrors(newPasswordError)
    setConfirmPasswordErrors(confirmPasswordError)
    return isValid
  }

  const handleRetry = () => {
    setInvalidEntry(false)
    setLogin(true)
  }

  const handleChangePassword = async (e) => {
    e.preventDefault()
    const isValid = confirmValidations()
    if (isValid) {
      setLoading(true)
      if (!password) {
        return
      }
      if (password !== passwordVerify) {
        alert('Password do not match')
        setLoading(false)
        return
      }
      try {
        await Auth.completeNewPassword(currentUser, password)
        setChangeModel(false)
        setChangeSuccess(true)
        history.push('/login')
        setLoading(false)
      } catch (error) {}
    }
  }
  const successClose = () => {
    setChangeSuccess(false)
    window.location.reload(false)
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login</title>
      </Helmet>
      <div className="Login-component">
        <div className="login-box">
          <div className="logoLogin">
            <img src={logoImg} alt="logo" />
          </div>
          <div className="login-text">Log In</div>
          <form onSubmit={handleSubmit}>
            <div className="form_groupL">
              <input
                type="email"
                className="form_controlL"
                id="email"
                name="Email"
                placeholder="Email"
                value={loginData.Email}
                onChange={(e) =>
                  setLoginData((prev) => ({
                    ...prev,
                    Email: e.target.value,
                  }))
                }
              />
              {Object.keys(emailErrors).map((key, index) => {
                return (
                  <div key={index} className="errorMessage">
                    {emailErrors[key]}
                    {''}
                  </div>
                )
              })}
            </div>
            <div className="form_groupL">
              <input
                type="password"
                className="form_controlL"
                id="password"
                name="Password"
                placeholder="Password"
                value={loginData.Password}
                onChange={(e) =>
                  setLoginData((prev) => ({
                    ...prev,
                    Password: e.target.value,
                  }))
                }
              />
              {Object.keys(passwordErrors).map((key, index) => {
                return (
                  <div key={index} className="errorMessage">
                    {passwordErrors[key]}
                    {''}
                  </div>
                )
              })}
            </div>
            <div className="forgotPassword">
              <button type="reset" id="forgot" onClick={forgotPassword}>
                Forgot Password ?
              </button>
            </div>

            <div className="footerButtons">
              <div className="logIn">
                <button type="submit">Log In</button>
              </div>
            </div>
          </form>
        </div>

        <ChangePassword
          showModel={changeModel}
          className={'change_content'}
          title={'Create a New Password'}
        >
          <form onSubmit={handleChangePassword}>
            <div className="form_group">
              <input
                type="text"
                className="form_control"
                id="newPassword"
                name="password"
                placeholder="Enter Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {Object.keys(newPasswordErrors).map((key, index) => {
                return (
                  <div key={index} className="errorMessage">
                    {newPasswordErrors[key]}
                    {''}
                  </div>
                )
              })}
            </div>
            <div className="form_group">
              <input
                type="text"
                className="form_control"
                id="newPassword"
                name="passwordVerify"
                placeholder="Confirm Password"
                value={passwordVerify}
                onChange={(e) => setPasswordVerify(e.target.value)}
              />
              {Object.keys(confirmPasswordErrors).map((key, index) => {
                return (
                  <div key={index} className="errorMessage">
                    {confirmPasswordErrors[key]}
                    {''}
                  </div>
                )
              })}
            </div>
            <div className="footerButtons">
              <div className="save">
                <button type="submit">Save</button>
              </div>
              <div className="closeLogin">
                <button id="closeL" onClick={handleCloseChange}>
                  {' '}
                  Close
                </button>
              </div>
            </div>
          </form>
        </ChangePassword>
        {forgotModal && (
          <div className="deleteModal">
            <div className="forgot_content">
              <div className="forgotModal_title">
                <h3> Contact the administrator to reset the password.</h3>
              </div>
              <div className="deleteModal_buttons">
                <div className="close">
                  <Button
                    label={'Close'}
                    primary={true}
                    size={'large'}
                    onClick={() => setForgotModal(false)}
                  ></Button>
                </div>
              </div>
            </div>
          </div>
        )}

        {expiredPassword && (
          <div className="deleteModal">
            <div className="forgot_content">
              <div className="forgotModal_title">
                <h3>Password expired , please contact the administrator.</h3>
              </div>
              <div className="deleteModal_buttons">
                <div className="close">
                  <Button
                    label={'Close'}
                    primary={true}
                    size={'large'}
                    onClick={onExpiry}
                  ></Button>
                </div>
              </div>
            </div>
          </div>
        )}

        {changeSuccess && (
          <div className="deleteModal">
            <div className="forgot_content">
              <div className="forgotModal_title">
                <h3>New password for the user is now created.</h3>
              </div>
              <div className="deleteModal_buttons">
                <div className="close">
                  <Button
                    label={'Close'}
                    primary={true}
                    size={'large'}
                    onClick={successClose}
                  ></Button>
                </div>
              </div>
            </div>
          </div>
        )}
        {invalidEntry && (
          <div className="deleteModal">
            <div className="invalid_content">
              <div className="invalid_title">
                <h3>Incorrect! Email or Password.</h3>
              </div>
              <div className="retry">
                <Button
                  label={'Retry'}
                  primary={true}
                  size={'large'}
                  onClick={handleRetry}
                ></Button>
              </div>
            </div>
          </div>
        )}

        {loading && <Loading />}
      </div>
    </>
  )
}

export default Login
