import React, { useState, useEffect, useRef } from 'react'
import { Header } from '../../components/header/Header'
import Sidebar from '../../components/sidebar/Sidebar'
import searchIcon from '../../assets/icons/Icon feather-search.svg'
import './apiManagement.scss'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import axios from 'axios'
import Loading from '../../components/loader/Loading'
import { Button } from '../../stories/Button/Button'
import { Delete } from '../../components/deleteModal/Delete'
import ReactPaginate from 'react-paginate'
import _ from 'lodash'
import { Auth } from 'aws-amplify'
import { Helmet } from 'react-helmet'

export const ApiManagement = () => {
  const [tableData, setTableData] = useState(null)
  const [rowId, setRowId] = useState()
  const [gridApi, setGridApi] = useState(null)
  const [loading, setLoading] = useState(false)
  const [showCustomer, setShowCustomer] = useState(false)
  const [showUpdatedKey, setShowUpdatedKey] = useState(false)
  const [showKey, setShowKey] = useState(false)
  const [apiKey, setApiKey] = useState()
  const [keyConfirm, setKeyConfirm] = useState(false)
  const [copySuccess, setCopySuccess] = useState(false)
  const [pageCount, setPageCount] = useState()
  const [pageSummary, setPageSummary] = useState()
  const [searchText, setSearchText] = useState('')
  const [currentApi, setCurrentApi] = useState()
  const accountId = Auth.Credentials.Auth
  const [apiSuccess, setApiSuccess] = useState(false)
  const textAreaRef = useRef(null)
  const columnDefs = [
    {
      headerName: 'Customer Name',
      field: 'CustomerName',
      suppressMovable: true,
      width: 250,
      cellStyle: { paddingTop: '20px', paddingLeft: '35px' },
    },
    {
      headerName: 'Customer No.',
      field: 'CustomerNo',
      suppressMovable: true,

      cellStyle: { paddingTop: '20px', paddingLeft: '30px' },
    },
    {
      headerName: 'Station',
      field: 'Station',
      suppressMovable: true,

      cellStyle: { paddingTop: '20px', paddingLeft: '40px' },
    },
    {
      headerName: 'Declared Type',
      field: 'DeclaredType',
      suppressMovable: true,

      cellStyle: { paddingTop: '20px', paddingLeft: '40px' },
    },
    {
      headerName: 'Status',
      field: 'CustomerStatus',
      suppressMovable: true,

      cellStyle: { paddingTop: '20px', paddingLeft: '35px' },
      cellClass: function (params) {
        return params.value === 'Active' ? 'active' : 'inactive'
      },
    },

    {
      headerName: 'Api Key Age',
      field: 'Age',
      suppressMovable: true,
      width: 200,
      cellStyle: { paddingTop: '18px', paddingLeft: '40px' },
    },
    {
      headerName: 'View Key',
      field: 'generateApi',
      width: 150,
      cellRendererFramework: (params) => (
        <div className="generateIcon">
          <button
            className="apiGenerate"
            onClick={() => {
              setShowCustomer(true)
              setRowId(params.data.CustomerID)
              setCurrentApi(params.data.ApiKey)
            }}
          >
            <svg
              className="viewIcon"
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="27"
              viewBox="0 0 36 27"
            >
              &gt;
              <path
                d="M1.5 18S7.5 6 18 6s16.5 12 16.5 12-6 12-16.5 12S1.5 18 1.5 18z"
                className="a"
                transform="translate(0 -4.5)"
              ></path>
              <path
                d="M22.5 18a4.5 4.5 0 11-4.5-4.5 4.5 4.5 0 014.5 4.5z"
                className="a"
                transform="translate(0 -4.5)"
              ></path>
            </svg>{' '}
          </button>
        </div>
      ),

      cellStyle: { paddingTop: '18px', paddingLeft: '35px' },
    },
    {
      headerName: 'Refresh Key',
      field: 'updateApi',
      width: 150,
      cellRendererFramework: (params) => (
        <div className="unionIcon">
          <button
            className="refreshButton"
            onClick={() => {
              setKeyConfirm(true)
              setRowId(params.data.CustomerID)
            }}
          >
            {/* <img className="uIcon" src={unionIcon} alt="uIcon" /> */}
            <svg
              className="uIcon"
              xmlns="http://www.w3.org/2000/svg"
              width="15.404"
              height="16.89"
              viewBox="0 0 15.404 16.89"
            >
              <path
                className="a"
                d="M-6302.74-4477.226a6.961,6.961,0,0,1-2.261-5.113,6.968,6.968,0,0,1,2.209-5.067,7.925,7.925,0,0,1,5.327-2.166v-1.668a.786.786,0,0,1,.808-.76.85.85,0,0,1,.431.118l.007,0,3.761,2.451a.747.747,0,0,1,.375.642.748.748,0,0,1-.374.643l-3.774,2.489a.837.837,0,0,1-.43.118.787.787,0,0,1-.809-.76v-1.836a6.022,6.022,0,0,0-6,5.793,6,6,0,0,0,6.184,5.792,6.067,6.067,0,0,0,6.16-5.308.749.749,0,0,1,.763-.659.8.8,0,0,1,.569.236.688.688,0,0,1,.194.544,7.034,7.034,0,0,1-2.451,4.707,7.982,7.982,0,0,1-5.235,1.917A7.932,7.932,0,0,1-6302.74-4477.226Z"
                transform="translate(6305 4492)"
              />
            </svg>
          </button>
        </div>
      ),
      cellStyle: { paddingTop: '18px', paddingLeft: '45px' },
    },
  ]

  function handleUpdateApi() {
    setLoading(true)
    setKeyConfirm(false)
    axios
      .put(`${process.env.REACT_APP_API_URL}keys`, {
        CustomerId: rowId,
        UserId: accountId.user.username,
      })
      .then((resp) => {
        setApiKey(resp.data)
        setLoading(false)
        setShowUpdatedKey(true)
        if (resp.status === 200) {
          return
        }
      })
      .catch((err) => {
        console.info(err)
        setLoading(false)
      })
  }

  const copyToClipboard = (e) => {
    textAreaRef.current.select()
    document.execCommand('copy')
    e.target.focus()
    setShowUpdatedKey(false)
    setShowKey(false)
    setCopySuccess(true)
  }

  const copyCurrentApi = (e) => {
    textAreaRef.current.select()
    document.execCommand('copy')
    e.target.focus()
    setShowUpdatedKey(false)
    setShowCustomer(false)
    setApiSuccess(true)
  }
  const onGridReady = (params) => {
    setGridApi(params)
  }

  const getApiUsers = async () => {
    setLoading(true)
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}customers?status=true&page=1&size=10`,
    )
    const data = await res.json()
    setTableData(data.Customers)
    setPageCount(data.Page.TotalPages)
    setLoading(false)
  }
  const fetchCustomers = async (currentPage) => {
    if (!searchText) {
      setLoading(true)
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}customers?status=true&page=${currentPage}&size=10`,
      )
      let data = await res.json()
      setTableData(data.Customers)
      setPageSummary(currentPage)
      setLoading(false)
      return data
    } else if (searchText) {
      setLoading(true)
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/search/${searchText}?status=true&page=${currentPage}&size=10`,
      )
      let data = await res.json()
      setTableData(data.Customers)
      setPageSummary(currentPage)
      setLoading(false)
      return data
    }
  }
  useEffect(() => {
    getApiUsers()
  }, [])

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1
    const customerServer = await fetchCustomers(currentPage)
    setTableData(customerServer)
  }

  const search = _.debounce((text) => {
    setSearchText(text)
  }, 1000)

  const searchFilter = async () => {
    if (searchText) {
      setLoading(true)
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/search/${searchText}?status=true&page=1&size=10`,
        )
        .then((res) => {
          setTableData(res.data.Customers)
          setPageCount(res.data.Page.TotalPages)
          setLoading(false)
        })
        .catch((err) => {
          setTableData([])
          setLoading(false)
        })
    } else {
      setLoading(true)
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/customers?status=true&page=1&size=10&`,
        )
        .then((res) => {
          setTableData(res.data.Customers)
          setPageCount(res.data.Page.TotalPages)
          setLoading(false)
        })
    }
  }
  const gridOptions = {
    rowStyle: {
      font: 'normal normal normal 12px/21px Poppins',
      color: '#71758A',
      opacity: 1,
    },
    getRowStyle: (params) => {
      if (params.node.rowIndex % 2 === 0) {
        return {
          background: '#F5F5F5 0% 0% no-repeat padding-box',
          opacity: 0.8,
          borderRadius: '10px',
          height: '70px',
        }
      } else {
        return {
          background: '#FAFAFA 0% 0% no-repeat padding-box',
          opacity: 0.8,
          borderRadius: '10px',
          height: '70px',
        }
      }
    },
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Api Management</title>
      </Helmet>
      {loading && <Loading />}

      <Header />
      <Sidebar />
      <div className="apiPageContent">
        <div className="apiManagementCard">
          <div className="apicardbody">
            <div className="apiLabelText">
              <h3>API Management</h3>
            </div>

            <div className="searchFilterAPI">
              <img src={searchIcon} alt="search" className="sIcon" />
              <input
                type="text"
                className="search_bar"
                placeholder="Find Customer"
                onChange={(e) => search(e.target.value)}
              ></input>
              <input type="submit" onClick={searchFilter}></input>

              {/* <span className="tooltiptext">Coming Soon</span> */}
            </div>

            <div
              className="ag-theme-balham"
              style={{
                width: '95%',
                height: '950px',
              }}
            >
              <AgGridReact
                columnDefs={columnDefs}
                gridOptions={gridOptions}
                rowData={tableData}
                pagination={true}
                onGridReady={onGridReady}
                headerHeight="50"
                rowHeight="80"
                suppressPaginationPanel={true}
                suppressHorizontalScroll={true}
                overlayLoadingTemplate={`<span className="ag-overlay-loading-center">
                </span>`}
              />
              {tableData && (
                <div className="mainPa">
                  <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    onPageChange={handlePageClick}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={1}
                    containerClassName={'pagination justify-content-end'}
                    pageCount={pageCount}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                    initialPage={0}
                  ></ReactPaginate>
                </div>
              )}

              <div className="summaryPanel">
                <span className="summary">
                  {pageSummary} of {pageCount}
                </span>
              </div>
            </div>

            <Delete
              onClose={() => setKeyConfirm(false)}
              showDelete={keyConfirm}
              title={'Are you sure you want to update the API for the customer'}
              onDelete={() => handleUpdateApi()}
            ></Delete>
            {showUpdatedKey && (
              <div className="keyModal">
                <div className="key_content">
                  <div className="key_title">
                    <h4>Updated Api Key</h4>
                  </div>
                  <div className="keyInput">
                    <textarea
                      ref={textAreaRef}
                      readOnly={true}
                      value={apiKey?.ApiKey}
                    />
                  </div>

                  <div className="keyButtons">
                    {document.queryCommandSupported('copy') && (
                      <div className="copyButton">
                        <Button
                          onClick={copyToClipboard}
                          label={'Copy'}
                          primary={true}
                        ></Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {copySuccess && (
              <div className="keyModal">
                <div className="key_content">
                  <div className="key_title">
                    <h4>Updated Api Key</h4>
                  </div>
                  <div className="keyInput">
                    <textarea
                      ref={textAreaRef}
                      readOnly={true}
                      value={apiKey?.ApiKey}
                    />
                  </div>
                  <div className="keyButtons">
                    <div className="copySButton">
                      <Button label={'Copied'} primary={true}></Button>
                    </div>
                    <div className="closeCopy">
                      <Button
                        label={'Close'}
                        className="closeC"
                        onClick={() => setCopySuccess(!copySuccess)}
                      ></Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {showKey && (
              <div className="keyModal">
                <div className="key_content">
                  <div className="key_title">
                    <h4>Generated Api Key</h4>
                  </div>
                  <div className="keyInput">
                    <textarea
                      ref={textAreaRef}
                      value={apiKey?.ApiKey}
                      readOnly={true}
                    />
                  </div>

                  <div className="keyButtons">
                    {document.queryCommandSupported('copy') && (
                      <div className="copyButton">
                        <Button
                          onClick={copyToClipboard}
                          label={'Copy'}
                          primary={true}
                        ></Button>
                      </div>
                    )}
                    <div className="closeCopy">
                      <Button
                        label={'close'}
                        className="closeC"
                        onClick={() => setShowKey(false)}
                      ></Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {showCustomer && (
              <div className="keyModal">
                <div className="key_content">
                  <div className="key_title">
                    <h4>Api Key</h4>
                  </div>
                  <div className="keyInput">
                    <textarea
                      ref={textAreaRef}
                      readOnly={true}
                      value={currentApi}
                    />
                  </div>
                  <div className="keyButtonsCurrent">
                    {document.queryCommandSupported('copy') && (
                      <div className="copyButton">
                        <Button
                          onClick={copyCurrentApi}
                          label={'Copy'}
                          primary={true}
                        ></Button>
                      </div>
                    )}
                    <div className="closeCopy">
                      <Button
                        label={'close'}
                        className="closeC"
                        onClick={() => setShowCustomer(false)}
                      ></Button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {apiSuccess && (
              <div className="keyModal">
                <div className="key_content">
                  <div className="key_title">
                    <h4>Api Key</h4>
                  </div>
                  <div className="keyInput">
                    <textarea
                      ref={textAreaRef}
                      readOnly={true}
                      value={currentApi}
                    />
                  </div>
                  <div className="keyButtons">
                    <div className="copySButton">
                      <Button label={'Copied'} primary={true}></Button>
                    </div>
                    <div className="closeCopy">
                      <Button
                        label={'Close'}
                        className="closeC"
                        onClick={() => setApiSuccess(!apiSuccess)}
                      ></Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ApiManagement
