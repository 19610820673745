import React,{useState,useEffect} from 'react';
import './App.css';
import Router from './Router';
import AppContext from './library/Context';
import { Auth } from "aws-amplify";


function App() {
  const [isAuthenticated, setUserHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  useEffect(() => {
    onLoad();
    userDetails()
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      setUserHasAuthenticated(true);
    }
    catch(e) {
      if (e !== 'No current user') {
        console.info(e);
      }
    }
  
    setIsAuthenticating(false);
  }

  async function userDetails(){
    try{
      await Auth.currentAuthenticatedUser()
      setUserHasAuthenticated(true)
    }
    catch(e){
      console.info(e)
    }
  }
  return (
    
    <AppContext.Provider value={{ isAuthenticated, setUserHasAuthenticated }}>
  <Router/>
</AppContext.Provider>
  );
}

export default App;
