import React, { useState, useEffect } from 'react'
import './customers.scss'
import { Header } from '../../components/header/Header'
import Sidebar from '../../components/sidebar/Sidebar'
import { Button } from '../../stories/Button/Button'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import { Modal } from '../../components/modal/Modal'
import { Delete } from '../../components/deleteModal/Delete'
import searchIcon from '../../assets/icons/Icon feather-search.svg'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import './ag-grid-overrides.scss'
// import DeclaredType from './DeclaredType'
import axios from 'axios'
import Loading from '../../components/loader/Loading'
import Edit from '../../components/editModal/Edit'
import ReactPaginate from 'react-paginate'
import { Auth } from 'aws-amplify'
import _ from 'lodash'
import { Helmet } from 'react-helmet'

const initialValues = {
  SourceSystem: '',
  CustomerNumber: '',
  DeclaredType: '',
  Station: '',
  CustomerName: '',
}
export const Customers = () => {
  const [tableData, setTableData] = useState(null)
  const [gridApi, setGridApi] = useState(null)
  const [showModel, setShowModel] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [showEditModel, setShowEditModel] = useState(false)
  const [rowId, setRowId] = useState()
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState(initialValues)
  const [deleteConfirm, setDeleteConfirm] = useState(false)
  const [editConfirm, setEditConfirm] = useState(false)
  const [addSuccess, setAddSuccess] = useState(false)
  const [pageCount, setPageCount] = useState()
  const [pageSummary, setPageSummary] = useState()
  const [nameErrors, setNameErrors] = useState({})
  const [customerErrors, setCustomerErrors] = useState({})
  const [declaredErrors, setDeclaredErrors] = useState({})
  const [sourceErrors, setSourceErrors] = useState({})
  const [stationErrors, setStationErrors] = useState({})
  const [searchText, setSearchText] = useState('')
  const [editFormData, setEditFormData] = useState({
    DeclaredType: '',
    Station: '',
  })
  const [editStationErrors, setEditStationError] = useState({})
  const [editDeclaredErrors, setEditDeclaredErrors] = useState({})
  const [customerExists, setCustomerExists] = useState(false)
  const [customerNot, setCustomerNot] = useState(false)
  const accountId = Auth.Credentials.Auth
  const [serverError, setServerError] = useState(false)

  const columnDefs = [
    {
      headerName: 'Customer Name',
      field: 'CustomerName',
      width: 200,
      suppressMovable: true,
      cellStyle: { paddingTop: '20px', paddingLeft: '38px' },
    },
    {
      headerName: 'Customer No.',
      field: 'CustomerNo',
      width: 200,
      suppressMovable: true,
      cellStyle: { paddingTop: '20px', paddingLeft: '38px' },
    },
    {
      headerName: 'Source System',
      field: 'SourceSystem',
      sortable: true,
      sortingOrder: ['asc', 'desc'],
      width: 200,
      suppressMovable: true,
      cellStyle: { paddingTop: '20px', paddingLeft: '40px' },
    },
    {
      headerName: 'Station',
      field: 'Station',
      width: 200,
      suppressMovable: true,
      cellStyle: { paddingTop: '20px', paddingLeft: '34px' },
    },
    {
      headerName: 'Declared Type',
      field: 'DeclaredType',
      width: 250,
      suppressMovable: true,
      cellStyle: { paddingTop: '20px', paddingLeft: '45px' },
    },
    {
      headerName: 'Status',
      field: 'CustomerStatus',
      // filter: 'DeclaredType',
      width: 220,
      suppressMovable: true,
      cellStyle: { paddingTop: '20px', paddingLeft: '30px' },
      cellClass: function (params) {
        return params.value === 'Active' ? 'active' : 'inactive'
      },
    },
    {
      headerName: 'Action',
      field: 'id',
      width: 120,
      suppressMovable: true,
      cellRendererFramework: (params) => (
        <div className="actionIcons">
          <button
            className="editButton"
            onClick={() => {
              setShowEditModel(!showEditModel)
              handleUpdate(params.data)
              setRowId(params.data.CustomerID)
            }}
          >
            {/* <img id="edit" src={editLogo} alt="edit" /> */}
            <svg
              id="edit"
              xmlns="http://www.w3.org/2000/svg"
              width="14.722"
              height="14.722"
              viewBox="0 0 14.722 14.722"
            >
              <path
                className="a"
                d="M13.293,18.318H4.025A2.027,2.027,0,0,1,2,16.293V7.025A2.027,2.027,0,0,1,4.025,5H8.659a.7.7,0,1,1,0,1.4H4.025a.624.624,0,0,0-.623.623v9.268a.624.624,0,0,0,.623.623h9.268a.624.624,0,0,0,.623-.623V11.659a.7.7,0,1,1,1.4,0v4.634A2.027,2.027,0,0,1,13.293,18.318Z"
                transform="translate(-2 -3.596)"
              />
              <path
                className="a"
                d="M19.645,1.818a2.105,2.105,0,0,1,1.489,3.594L14.845,11.7a.7.7,0,0,1-.326.184l-2.648.662a.7.7,0,0,1-.85-.85l.662-2.648a.7.7,0,0,1,.184-.326l6.289-6.289A2.092,2.092,0,0,1,19.645,1.818Zm-5.654,8.754,6.152-6.152a.7.7,0,1,0-.995-.995L13,9.578,12.665,10.9Z"
                transform="translate(-7.028 -1.818)"
              />
            </svg>
          </button>
          <button
            className="deleteButton"
            onClick={() => {
              setShowDelete(true)
              setRowId(params.data.CustomerID)
            }}
          >
            {/* <img id="delete" src={deleteLogo} alt="delete" /> */}
            <svg
              id="delete"
              xmlns="http://www.w3.org/2000/svg"
              width="10.753"
              height="14.961"
              viewBox="0 0 10.753 14.961"
            >
              <path
                className="a"
                d="M8.268,17.8A1.608,1.608,0,0,0,9.8,19.461h6.145A1.608,1.608,0,0,0,17.485,17.8V7.825H8.268ZM18.253,5.331H15.565L14.8,4.5h-3.84l-.768.831H7.5V6.993H18.253Z"
                transform="translate(-7.5 -4.5)"
              />
            </svg>
          </button>
        </div>
      ),
      cellStyle: { paddingTop: '18px', paddingLeft: '20px' },
    },
  ]
  // const frameworkComponents = {
  //   DeclaredType: DeclaredType,
  // }
  const gridOptions = {
    rowStyle: {
      font: 'normal normal normal 14px/21px Poppins',
      color: '#71758A',
      opacity: 1,
    },
    getRowStyle: (params) => {
      if (params.node.rowIndex % 2 === 0) {
        return {
          background: '#F5F5F5 0% 0% no-repeat padding-box',
          opacity: 0.8,
          borderRadius: '10px',
          height: '70px',
        }
      } else {
        return {
          background: '#FAFAFA 0% 0% no-repeat padding-box',
          opacity: 0.8,
          borderRadius: '10px',
          height: '70px',
        }
      }
    },
  }

  const getUsers = async () => {
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}customers?status=true`)
      .then((res) => {
        setTableData(res.data.Customers)
        setPageCount(res.data.Page.TotalPages)
        setPageSummary(1)
        setLoading(false)
      })
  }
  useEffect(() => {
    getUsers()
  }, [])

  const fetchCustomers = async (currentPage, startKey) => {
    if (!searchText) {
      setLoading(true)

      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/customers?status=true&page=${currentPage}`,
      )
      let data = await res.json()
      setTableData(data.Customers)
      setPageSummary(currentPage)
      setLoading(false)
      return data
    } else if (searchText) {
      setLoading(true)
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/search/${searchText}?status=true&page=${currentPage}&size=10`,
      )
      let data = await res.json()
      setTableData(data.Customers)
      setPageSummary(currentPage)
      setLoading(false)
      return data
    }
  }
  const search = _.debounce((text) => {
    setSearchText(text)
  }, 1000)

  const searchFilter = async () => {
    if (searchText) {
      setLoading(true)
      // const res = await fetch(
      //   `${process.env.REACT_APP_API_URL}/search/${searchText}?status=true&page=1&size=10`,
      // )

      // let data = await res.json()
      // setTableData(data.Customers)
      // setPageCount(data.Page.TotalPages)
      // setLoading(false)
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/search/${searchText}?status=true&page=1&size=10`,
        )
        .then((res) => {
          setTableData(res.data.Customers)
          setPageCount(res.data.Page.TotalPages)
          setLoading(false)
        })
        .catch((err) => {
          setTableData([])
          setLoading(false)
        })
    } else {
      setLoading(true)
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/customers?status=true&page=1&size=10&`,
        )
        .then((res) => {
          setTableData(res.data.Customers)
          setPageCount(res.data.Page.TotalPages)
          setLoading(false)
        })
        .catch((err) => {
          setTableData([])
          setLoading(false)
        })
    }
  }
  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1
    const customerServer = await fetchCustomers(currentPage)
    setTableData(customerServer)
  }

  const onGridReady = (params) => {
    setGridApi(params)
  }
  const errorHandlers = () => {
    setServerError(true)
    setShowModel(false)
    setFormData(initialValues)
  }

  function onSubmit(e) {
    e.preventDefault()
    const isValid = formValidation()
    if (isValid) {
      setLoading(true)
      axios
        .post(`${process.env.REACT_APP_API_URL}/customers`, {
          UserId: accountId.user.username,
          SourceSystem: formData.SourceSystem,
          CustomerNumber: formData.CustomerNumber,
          DeclaredType: formData.DeclaredType,
          Station: formData.Station,
          CustomerName: formData.CustomerName,
        })
        .then((res) => {
          setShowModel(false)
          getUsers()
          setLoading(false)
          setFormData(initialValues)
          if (res.status === 202 || res.status === 200) {
            setAddSuccess(true)
          }

          // else if (res.status === 400){
          //   setCustomerExists(true)
          // }
        })
        .catch((err) => {
          setLoading(false)
          if (err.response.data.code === 1021) {
            setCustomerExists(true)
            setShowModel(false)
            setFormData(initialValues)
          } else if (err.response.data.code === 1022) {
            setCustomerNot(true)
            setShowModel(false)
            setFormData(initialValues)
          } else if (err.response.data.code === 1003) {
            errorHandlers()
          } else if (err.response.data.code === 1000) {
            errorHandlers()
          } else if (err.response.data.code === 1001) {
            errorHandlers()
          } else if (err.response.data.code === 1002) {
            errorHandlers()
          } else if (err.response.data.code === 1004) {
            errorHandlers()
          } else if (err.response.data.code === 1005) {
            errorHandlers()
          } else if (err.response.data.code === 1006) {
            errorHandlers()
          } else if (err.response.data.code === 1007) {
            errorHandlers()
          } else if (err.response.data.code === 1008) {
            errorHandlers()
          } else if (err.response.data.code === 1009) {
            errorHandlers()
          } else if (err.response.data.code === 1010) {
            errorHandlers()
          } else if (err.response.data.code === 1011) {
            errorHandlers()
          } else if (err.response.data.code === 1012) {
            errorHandlers()
          } else if (err.response.data.code === 1013) {
            errorHandlers()
          } else if (err.response.data.code === 1014) {
            errorHandlers()
          } else if (err.response.data.code === 1015) {
            errorHandlers()
          } else if (err.response.data.code === 1016) {
            errorHandlers()
          } else if (err.response.data.code === 1017) {
            errorHandlers()
          } else if (err.response.data.code === 1018) {
            errorHandlers()
          } else if (err.response.data.code === 1019) {
            errorHandlers()
          } else if (err.response.data.code === 1020) {
            errorHandlers()
          }
        })
    }
  }
  function formValidation() {
    const nameError = {}
    const customerError = {}
    const declaredError = {}
    const sourceError = {}
    const stationError = {}
    const regex1 = /^[a-zA-Z0-9]*$/
    const regex2 = /^[a-zA-Z\s]*$/
    let isValid = true

    if (formData.CustomerName.trim().length < 1) {
      nameError.nameEmpty = 'Name is required!'
      isValid = false
    } else if (!regex2.test(formData.CustomerName)) {
      nameError.nameSpe = 'No special characters allowed'
      isValid = false
    }

    if (formData.CustomerNumber.trim().length < 1) {
      customerError.customerEmpty = 'Customer number is required!'
      isValid = false
    } else if (!regex1.test(formData.CustomerNumber)) {
      customerError.customerSpe = 'No special characters allowed'
      isValid = false
    }
    if (formData.SourceSystem.trim().length < 1) {
      sourceError.sourceEmpty = 'Source System is required!'
      isValid = false
    } else if (formData.SourceSystem.trim().length !== 2) {
      sourceError.sourceSpe = 'Must be equal to 2 characters '
      isValid = false
    }

    if (formData.DeclaredType.trim().length < 1) {
      declaredError.declaredEmpty = 'Declared type is required!'
      isValid = false
    } else if (!regex1.test(formData.DeclaredType)) {
      declaredError.declaredSpe = 'No special characters allowed'
      isValid = false
    }
    if (formData.Station.trim().length < 1) {
      stationError.stationEmpty = 'Station is required!'
      isValid = false
    } else if (!regex1.test(formData.Station)) {
      stationError.stationSpe = 'No special characters allowed'
      isValid = false
    }

    setNameErrors(nameError)
    setCustomerErrors(customerError)
    setSourceErrors(sourceError)
    setDeclaredErrors(declaredError)
    setStationErrors(stationError)

    return isValid
  }

  const onEdit = (e) => {
    e.preventDefault()
    const isValid = editValidations()
    if (isValid) {
      setLoading(true)
      setShowEditModel(false)
      axios
        .put(`${process.env.REACT_APP_API_URL}/customers`, {
          CustomerId: rowId,
          DeclaredType: editFormData.DeclaredType,
          Station: editFormData.Station,
          UserId: accountId.user.username,
        })
        .then((res) => {
          getUsers()
          setLoading(false)
          if (res.status === 200 || res.status === 202) {
            setEditConfirm(true)
            return
          }

          setFormData(initialValues)
        })
        .catch((err) => {
          console.info(err)
        })
    }
  }

  function editValidations() {
    const editStationError = {}
    const editDeclaredError = {}

    let isValid = true

    if (editFormData.Station.trim().length < 1) {
      editStationError.sEmpty = 'Field is required!'
      isValid = false
    }

    if (editFormData.DeclaredType.trim().length < 1) {
      editDeclaredError.dEmpty = 'Field is required!'
      isValid = false
    }
    setEditStationError(editStationError)
    setEditDeclaredErrors(editDeclaredError)
    return isValid
  }

  const handleDelete = () => {
    setLoading(true)
    setShowDelete(false)
    axios
      .delete(`${process.env.REACT_APP_API_URL}/customers`, {
        data: { CustomerId: rowId, UserId: accountId.user.username },
      })
      .then((resp) => {
        getUsers()
        setLoading(false)
        if (resp.status === 200 || resp.status === 202) {
          setDeleteConfirm(true)
        }
      })
      .catch((err) => {
        console.info(err)
      })
  }

  const handleUpdate = (prevData) => {
    setEditFormData(prevData)
    setShowEditModel(true)
  }
  const handleClose = () => {
    setShowModel(false)
    setFormData(initialValues)
    setNameErrors('')
    setCustomerErrors('')
    setSourceErrors('')
    setDeclaredErrors('')
    setStationErrors('')
  }
  const editClose = () => {
    setShowEditModel(false)
    setEditStationError('')
    setEditDeclaredErrors('')
  }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Customers</title>
      </Helmet>
      <Header />
      <Sidebar />
      <div className="customers_content">
        <div className="customers_card_content">
          <div className="cardCustomers">
            <div className="customers_card_body">
              <div className="customers_label_text">
                <h3>Customers</h3>
              </div>

              <div className="searchFilter">
                <img src={searchIcon} alt="search" className="sIcon" />
                <input
                  type="text"
                  className="search_bar"
                  placeholder="Find Customer"
                  onChange={(e) => search(e.target.value)}
                ></input>
                <input type="submit" onClick={searchFilter}></input>
                {/* <span className="tooltipText">Coming Soon</span> */}
              </div>

              <div className="addCustomer">
                <Button
                  label={'Add Customer'}
                  onClick={() => setShowModel(true)}
                ></Button>

                <Modal
                  title="Add New Customer"
                  onClose={() => setShowModel(false)}
                  showModel={showModel}
                  className={'modal_content'}
                >
                  <form onSubmit={onSubmit}>
                    <div className="form_group">
                      <input
                        type="text"
                        className="form_control"
                        id="name"
                        name="Name"
                        placeholder="Name"
                        value={formData.CustomerName}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            CustomerName: e.target.value,
                          }))
                        }
                      />
                      {/* <span className="floating-label">Name</span>{' '} */}
                      {Object.keys(nameErrors).map((key, index) => {
                        return (
                          <div key={index} className="errorMessage">
                            {nameErrors[key]}{' '}
                          </div>
                        )
                      })}
                    </div>

                    <div className="form_group">
                      <input
                        type="text"
                        className="form_control"
                        id="customer"
                        name="CustomerNo"
                        placeholder="Customer No."
                        value={formData.CustomerNumber}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            CustomerNumber: e.target.value,
                          }))
                        }
                      />
                      {/* <span className="floating-labelC">Customer No.</span>{' '} */}
                      {Object.keys(customerErrors).map((key, index) => {
                        return (
                          <div key={index} className="errorMessage">
                            {customerErrors[key]}{' '}
                          </div>
                        )
                      })}
                    </div>
                    <div className="form_group">
                      <input
                        type="text"
                        className="form_control"
                        id="source"
                        name="source"
                        placeholder="Source System"
                        value={formData.SourceSystem}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            SourceSystem: e.target.value,
                          }))
                        }
                      />
                      {/* <span className="floating-labelS">Source System</span>{' '} */}
                      {Object.keys(sourceErrors).map((key, index) => {
                        return (
                          <div key={index} className="errorMessage">
                            {sourceErrors[key]}{' '}
                          </div>
                        )
                      })}
                    </div>
                    <div className="form_group">
                      <input
                        type="text"
                        className="form_control"
                        id="declared"
                        name="declared"
                        placeholder="Declared Type"
                        value={formData.DeclaredType}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            DeclaredType: e.target.value,
                          }))
                        }
                      />
                      {/* <span className="floating-labelD">Declared Type</span>{' '} */}
                      {Object.keys(declaredErrors).map((key, index) => {
                        return (
                          <div key={index} className="errorMessage">
                            {declaredErrors[key]}{' '}
                          </div>
                        )
                      })}
                    </div>

                    <div className="form_group">
                      <input
                        className="form_control"
                        type="text"
                        id="station"
                        name="Station"
                        value={formData.Station}
                        placeholder="Station"
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            Station: e.target.value,
                          }))
                        }
                      />
                      {/* <span className="floating-labelST">Station</span>{' '} */}
                      {Object.keys(stationErrors).map((key, index) => {
                        return (
                          <div key={index} className="errorMessage">
                            {stationErrors[key]}{' '}
                          </div>
                        )
                      })}
                    </div>

                    <div className="footerButtons">
                      <div className="save">
                        <button type="submit">Save</button>
                      </div>

                      <div className="cancelButton">
                        <button onClick={handleClose}>Close</button>
                      </div>
                    </div>
                  </form>
                </Modal>

                {addSuccess && (
                  <div className="deleteModal">
                    <div className="deleteModal_content">
                      <div className="deleteModal_title">
                        <h3>Customer added successfully!</h3>
                      </div>

                      <div className="deleteModal_buttons">
                        <div className="close">
                          <Button
                            label={'Close'}
                            primary={true}
                            size={'large'}
                            onClick={() => setAddSuccess(false)}
                          ></Button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {customerExists && (
                  <div className="deleteModal">
                    <div className="deleteModal_content">
                      <div className="deleteModal_titleEx">
                        <h3>Customer already exists.</h3>
                      </div>

                      <div className="deleteModal_buttons">
                        <div className="close">
                          <Button
                            label={'Close'}
                            primary={true}
                            size={'large'}
                            onClick={() => setCustomerExists(false)}
                          ></Button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {customerNot && (
                  <div className="deleteModal">
                    <div className="deleteModal_content">
                      <div className="deleteModal_titleEx">
                        <h3>Customer does not exists.</h3>
                      </div>

                      <div className="deleteModal_buttons">
                        <div className="close">
                          <Button
                            label={'Close'}
                            primary={true}
                            size={'large'}
                            onClick={() => setCustomerNot(false)}
                          ></Button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {serverError && (
                  <div className="deleteModal">
                    <div className="deleteModal_content">
                      <div className="deleteModal_titleEx">
                        <h3>
                          Internal Server Error.
                          <br />
                          Please contact support.
                        </h3>
                      </div>

                      <div className="deleteModal_buttons">
                        <div className="close">
                          <Button
                            label={'Close'}
                            primary={true}
                            size={'large'}
                            onClick={() => setServerError(false)}
                          ></Button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div
                className="ag-theme-balham"
                style={{
                  width: '90%',
                  height: 950,
                  marginLeft: 12,
                }}
              >
                <AgGridReact
                  columnDefs={columnDefs}
                  rowData={tableData}
                  onGridReady={onGridReady}
                  gridOptions={gridOptions}
                  pagination={true}
                  paginationPageSize={10}
                  paginationAutoPageSize={true}
                  suppressScrollOnNewData={true}
                  suppressHorizontalScroll={true}
                  // frameworkComponents={frameworkComponents}
                  rowSelection="multiple"
                  headerHeight="50"
                  suppressPaginationPanel={true}
                  suppressDragLeaveHidesColumns={true}
                  rowHeight="80"
                  overlayLoadingTemplate={`<span className="ag-overlay-loading-center">
                  </span>`}
                ></AgGridReact>
                {tableData && (
                  <div className="mainPa">
                    <ReactPaginate
                      previousLabel={'<'}
                      nextLabel={'>'}
                      onPageChange={handlePageClick}
                      initialPage={0}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={1}
                      containerClassName={'pagination justify-content-end'}
                      disabledClassName={'disabledPagination'}
                      pageCount={pageCount}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      previousClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      nextClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      activeClassName={'active'}
                    ></ReactPaginate>
                  </div>
                )}

                <div className="summaryPanel">
                  <span className="summary">
                    {pageSummary} of {pageCount}
                  </span>
                </div>
              </div>

              <Delete
                onClose={() => setShowDelete(false)}
                showDelete={showDelete}
                title={'Are you sure you want to delete this customer '}
                onDelete={() => handleDelete()}
              />

              {deleteConfirm && (
                <div className="deleteModal">
                  <div className="deleteModal_content">
                    <div className="deleteModal_title">
                      <h3>Customer deleted successfully!</h3>
                    </div>

                    <div className="deleteModal_buttons">
                      <div className="close">
                        <Button
                          label={'Close'}
                          primary={true}
                          size={'large'}
                          onClick={() => setDeleteConfirm(false)}
                        ></Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <Edit
                title="Edit Customer"
                onClose={editClose}
                showEditModel={showEditModel}
                onEdit={onEdit}
                className={'edit_content'}
              >
                <form onSubmit={onEdit}>
                  <div className="form_groupE">
                    <label className="labelEdit">
                      Station
                      <input
                        className="form_controlE"
                        type="text"
                        id="station"
                        name="station"
                        value={editFormData.Station}
                        onChange={(e) =>
                          setEditFormData((prev) => ({
                            ...prev,
                            Station: e.target.value,
                          }))
                        }
                      />
                      {Object.keys(editStationErrors).map((key, index) => {
                        return (
                          <div key={index} className="errorMessage">
                            {editStationErrors[key]}
                            {''}
                          </div>
                        )
                      })}
                    </label>
                  </div>
                  <div className="form_groupE">
                    <label className="labelEditD">
                      Declared Type
                      <input
                        type="text"
                        className="form_controlE"
                        id="declared"
                        name="declared"
                        value={editFormData.DeclaredType}
                        onChange={(e) =>
                          setEditFormData((prev) => ({
                            ...prev,
                            DeclaredType: e.target.value,
                          }))
                        }
                      />
                      {Object.keys(editDeclaredErrors).map((key, index) => {
                        return (
                          <div key={index} className="errorMessage">
                            {editDeclaredErrors[key]}
                            {''}
                          </div>
                        )
                      })}
                    </label>
                  </div>
                  <div className="footerButtonsE">
                    <div className="save">
                      <button type="submit">Save</button>
                    </div>
                    <div className="cancelButton">
                      <button onClick={() => setShowEditModel(false)}>
                        Close
                      </button>
                    </div>
                  </div>
                </form>
              </Edit>
              {editConfirm && (
                <div className="deleteModal">
                  <div className="deleteModal_content">
                    <div className="deleteModal_title">
                      <h3>Customer edited successfully!</h3>
                    </div>

                    <div className="deleteModal_buttons">
                      <div className="close">
                        <Button
                          label={'Close'}
                          primary={true}
                          size={'large'}
                          onClick={() => setEditConfirm(false)}
                        ></Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {loading && <Loading />}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Customers
