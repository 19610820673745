import React, { useState, useEffect } from 'react'
import { Header } from '../../components/header/Header'
import Sidebar from '../../components/sidebar/Sidebar'
import './userActivity.scss'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import Loading from '../../components/loader/Loading'
import ReactPaginate from 'react-paginate'
import { Auth } from 'aws-amplify'
import { Helmet } from 'react-helmet'
import { getUser } from '../../api/userActivity/User'

export const UserActivity = () => {
  const [loading, setLoading] = useState(false)
  const [gridApi, setGridApi] = useState(null)
  const [pageCount, setPageCount] = useState()
  const [tableData, setTableData] = useState(null)
  const [pageSummary, setPageSummary] = useState()

  const accountId = Auth.Credentials.Auth

  const columnDefs = [
    {
      headerName: 'Activity',
      field: 'Activity',
      suppressMovable: true,
      width: 300,
      cellStyle: { paddingLeft: '30px', paddingTop: '20px' },
    },
    {
      headerName: 'Date',
      field: 'Timestamp',
      width: 370,
      suppressMovable: true,

      cellStyle: { paddingLeft: '30px', paddingTop: '20px' },
    },
    {
      headerName: 'Operational Detail',
      field: 'Description',
      width: 800,
      suppressMovable: true,

      cellStyle: {
        paddingLeft: '25px',
        paddingTop: '18px',
        color: '#181C32',
      },
    },
  ]

  useEffect(() => {
    // async function getUserActivity() {
    setLoading(true)
    getUser(accountId.user.attributes.sub).then((data) => {
      setLoading(false)
      setTableData(data.entity.Activity)
      setPageCount(data.entity.Page.TotalPages)
      setPageSummary(1)
    })
    // }
    // getUserActivity()
  }, [])

  const fetchUserActivity = async (currentPage) => {
    setLoading(true)
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}activity/${accountId.user.attributes.sub}?page=${currentPage}&size=10`,
    )
    let data = await res.json()
    setLoading(false)
    setTableData(data.Activity)
    setPageSummary(currentPage)
    return data
  }

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1
    const cusotmerServer = await fetchUserActivity(currentPage)
    setTableData(cusotmerServer)
  }
  const onGridReady = (params) => {
    setGridApi(params)
  }
  const gridOptions = {
    rowStyle: {
      font: 'normal normal normal 12px/21px Poppins',
      color: '#71758A',
    },
    getRowStyle: (params) => {
      if (params.node.rowIndex % 2 === 0) {
        return {
          background: '#F5F5F5 0% 0% no-repeat padding-box',
          opacity: 0.8,
          borderRadius: '10px',
          height: '70px',
        }
      } else {
        return {
          background: '#FAFAFA 0% 0% no-repeat padding-box',
          opacity: 0.8,
          borderRadius: '10px',
          height: '70px',
        }
      }
    },
    suppressRowTransform: true,
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>User Activity</title>
      </Helmet>

      {loading && <Loading id="load" />}
      <Header />
      <Sidebar />
      <div className="userPageContent">
        <div className="userPageCard">
          <div className="userCardBody">
            <div className="d-flex justify-content-between">
              <div className="userLabelText">
                <h3>User Activity</h3>
              </div>
              {/* <div className="filterButtons">
                <Button id="all" label={'All'} size={'medium'} />
                <Button id="today" label={'Today'} size={'medium'} />

                <Button id="custom" label={'Custom'} size={'medium'} />
                <span className="tooltipText">Coming Soon</span>
              </div> */}
            </div>
            <div
              className="ag-theme-balham"
              style={{
                width: '95%',
                height: '950px',
              }}
            >
              <AgGridReact
                columnDefs={columnDefs}
                rowData={tableData}
                gridOptions={gridOptions}
                onGridReady={onGridReady}
                pagination={true}
                paginationPageSize={10}
                suppressRowTransform={true}
                paginationAutoPageSize={true}
                suppressPaginationPanel={true}
                suppressHorizontalScroll={true}
                headerHeight="50"
                rowHeight="80"
                overlayLoadingTemplate={`<span className="ag-overlay-loading-center">
                    </span>`}
              />
              {tableData && (
                <div className="mainPa">
                  <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    onPageChange={handlePageClick}
                    initialPage={0}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={1}
                    containerClassName={'pagination justify-content-end'}
                    disabledClassName={'disabledPagination'}
                    pageCount={pageCount}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                  ></ReactPaginate>
                </div>
              )}
              <div className="summaryPanel">
                <span className="summary">
                  {pageSummary} of {pageCount}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserActivity
